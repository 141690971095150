import React from 'react';

import { Auth0Context } from "@auth0/auth0-react";

import {
    Container,
    Card,
    Row,
    Col,
    Button,
    Form
} from 'react-bootstrap';

import api_url from "../api_url";

import human from '../images/human.png';
import ida from '../images/ida.png';
import InvokeIDAInteractionList from './InvokeIDAInteractionList';

class InvokeIDA extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            jrrToken: null,
            interactionHistory: [],
            idaFormatHistory: null,
            historyLoaded: false,
            question: "",
            allowInput: false,
        };


        this.clearInteractionHistory = this.clearInteractionHistory.bind(this);
        this.submitQuestion = this.submitQuestion.bind(this);
        this.setQuestion = this.setQuestion.bind(this);
    }

    componentDidMount() {
        const { getAccessTokenSilently } = this.context;
        getAccessTokenSilently({
            audience: api_url,
        }).then((t) => {
            this.setState({ jrrToken: "Bearer " + t });
            this.initializeData();
        }).catch((error) => {
            console.log(error);
        });
    }

    componentWillUnmount() {
    }

    initializeData() {
        this.getInteractionHistory();
    }

    getInteractionHistory() {
        const username = this.context.user.name;

        const options = {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_history'
            }
        };

        fetch(
            `${api_url}/ida/history/${username}`,
            options
        ).then(
            response => response.json()
        ).then(
            (data) => {
                let interactionHistory = [];
                let idaFormatHistory = [];
                data.history.forEach(element => {
                    idaFormatHistory.push([element.prompt, element.result]);
                    interactionHistory.push({
                        "type": "human",
                        "text": element.prompt,
                        "history_id": element.history_id,
                    });
                    interactionHistory.push({
                        "type": "ida",
                        "text": element.result,
                        "history_id": element.history_id,
                    });
                });
                this.setState({
                    interactionHistory: interactionHistory,
                    idaFormatHistory: idaFormatHistory,
                    historyLoaded: true,
                    allowInput: true,
                });
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    clearInteractionHistory() {
        const username = this.context.user.name;

        const options = {
            mode: 'cors',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_history'
            }
        };

        this.setState({
            historyLoaded: false,
            allowInput: false,
        });

        fetch(
            `${api_url}/ida/history/${username}`,
            options
        ).then(
            response => response.json()
        ).then(
            (data) => {
                this.setState({
                    interactionHistory: [],
                    historyLoaded: true,
                    allowInput: true,
                });
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    setQuestion(q) {
        this.setState({ question: q.target.value });
    }

    submitQuestion() {
        // Append a 'loading' messsage to the chat history
        let interactionHistory = this.state.interactionHistory;
        interactionHistory.push({
            "type": "human",
            "text": this.state.question,
        });
        interactionHistory.push({
            "type": "ida_thinking",
            "text": "Hmm, let me think about that..."
        });
        this.setState({
            interactionHistory: interactionHistory,
            allowInput: false,
        });

        // Submit request and deal with response
        const username = this.context.user.name;
        const post_data = JSON.stringify({
            question: this.state.question,
        });

        const options = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_interaction'
            },
            body: post_data,
        };

        fetch(
            `${api_url}/ida/interact/${username}`,
            options
        ).then(
            response => response.json()
        ).then(
            (data) => {
                let interactionHistory = [];
                data.history.forEach(element => {
                    interactionHistory.push({
                        "type": "human",
                        "text": element.prompt,
                        "history_id": element.history_id,
                    });
                    interactionHistory.push({
                        "type": "ida",
                        "text": element.result,
                        "history_id": element.history_id,
                    });
                });
                this.setState({
                    interactionHistory: interactionHistory,
                    idaFormatHistory: data,
                    allowInput: true,
                });
            }
        ).catch(
            (error) => console.log(error)
        );
    }


    render() {
        let history = (<button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
        </button>);

        if (this.state.historyLoaded) {
            history = (
                <InvokeIDAInteractionList
                    jrrToken={this.state.jrrToken}
                    interactionHistory={this.state.interactionHistory}
                />);

        }

        return (
            <Container>
                <Row className="my-2">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Invoke Data Assistant (IDA)</Card.Title>
                                <hr />
                                <Row className="mb-3">
                                    <Col md={{ span: 2 }} className="text-end"><img src={ida} height="80" width="80" alt="I.D.A." /></Col>
                                    <Col md={{ span: 7 }}>
                                        <Card className="rounded ida_bubble">
                                            <Card.Body><p>I'm here to help you query your data! Ask me your data questions and I'll do my best to help.</p></Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {history}
                                {this.state.allowInput &&
                                    <Row className="mb-3">
                                        <Col md={{ span: 7, offset: 3 }} className="text-start">
                                            <Card className="rounded human_bubble">
                                                <Card.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="user_question">
                                                            <Form.Label>Enter your question below:</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                value={this.state.question}
                                                                onChange={this.setQuestion}
                                                                disabled={!this.state.allowInput}
                                                            />
                                                        </Form.Group>
                                                        <Button variant="human" onClick={this.submitQuestion} disabled={!this.state.allowInput}>
                                                            Ask IDA
                                                        </Button>&nbsp;&nbsp;
                                                        <Button variant="human" onClick={this.clearInteractionHistory}>
                                                            Reset
                                                        </Button>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={{ span: 2 }}><img src={human} height="80" width="80" alt="HUMAN PERSON" /></Col>
                                    </Row>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default InvokeIDA;