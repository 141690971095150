import InvokeEmbeddedSupersetDashboard from "./InvokeEmbeddedSupersetDashboard";

function InvokeDEI(props) {
  return(
    <InvokeEmbeddedSupersetDashboard
      dashboardType="InvokeDEI"
      dashboardDisplayName="Invoke DEI"
    />
  );
}

export default InvokeDEI;