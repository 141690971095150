import React from 'react';

import { Auth0Context } from "@auth0/auth0-react";

import {
  Table,
  Row,
  Col,
  Card,
  Accordion,
  Button,
  Spinner,
} from 'react-bootstrap';
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from "react-bs-datatable";

import api_url from "../api_url";
import ida from '../images/ida.png';

class InvokeIDAResponse extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    this.state = {
      response: "",
      code: "",
      headers: [],
      data: [],
      dataRetrieved: false,
      generatingDownload: false,
      downloadLink: null,
    };

    this.getDownload = this.getDownload.bind(this);
  }

  componentDidMount() {
    this.initializeData();
  }

  initializeData() {
    let [sql_code, everything_else] = this.props.response.split(';');
    if (typeof everything_else === 'undefined') {
      this.setState({
        response: this.props.response,
        code: "",
      });
    } else {
      this.setState({
        response: everything_else,
        code: sql_code + ";",
      });
      this.populateDataTable(sql_code);
    }
  }

  populateDataTable(sql_code) {
    // Submit request and deal with response
    const username = this.context.user.name;

    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.props.jrrToken,
        'Invoke-Action': 'retrieve_sample'
      },
    };

    fetch(
      `${api_url}/ida/sample/${username}/${this.props.history_id}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        if (typeof data.headers === 'object' && typeof data.data === 'object') {
          this.setState({
            headers: data.headers,
            data: data.data,
            dataRetrieved: true,
          });
        }
      }
    ).catch(
      (error) => {
        this.setState({
          headers: [],
          data: [],
          dataRetrieved: true,
        });
        console.log(error);
      }
    );
  }

  getDownload() {
    // Submit request and deal with response
    const username = this.context.user.name;

    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.props.jrrToken,
        'Invoke-Action': 'download_dataset'
      },
    };

    this.setState({ generatingDownload: true })

    fetch(
      `${api_url}/ida/download/${username}/${this.props.history_id}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        if (data.link !== null) {
          this.setState({
            downloadLink: data.link,
            generatingDownload: false,
          });
        }
      }
    ).catch(
      (error) => {
        this.setState({
          downloadLink: null,
          generatingDownload: false,
        });
        console.log(error);
      }
    );
  }


  render() {
    let extras = "";
    let data_table = "";
    let paginationRow = "";
    let downloadButton = "";

    if (this.state.code !== "") {
      extras = (
        <Accordion variant="ida">
          <Accordion.Item eventKey="0">
            <Accordion.Header>View Code</Accordion.Header>
            <Accordion.Body>
              {this.state.code}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );

      if (!this.state.dataRetrieved) {
        data_table = (
          <Button variant="ida" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />&nbsp;&nbsp;Loading Data...
          </Button>
        );
      } else {
        if (this.state.headers.length === 0) {
          data_table = (
            <Button variant="ida" disabled>
              Query Returned No Data.
            </Button>
          );
        } else {
          if (this.state.data.length > 10) {
            paginationRow = (
              <Row className="mb-4 p-2">
                <Col
                  xs={12}
                  sm={6}
                  lg={6}
                  className="d-flex flex-col justify-content-start align-items-center justify-content-sm-start mb-2 mb-sm-0"
                >
                  <PaginationOptions />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={6}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Pagination
                    classes={{
                      button: "btn-ida",
                      buttonGroup: "btngrp-ida"
                    }} />
                </Col>
              </Row>
            );
          }

          if (this.state.generatingDownload) {
            downloadButton = (
              <Button variant="ida" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />&nbsp;&nbsp;Preparing Download...
              </Button>);
          } else {
            if (this.state.downloadLink === null) {
              downloadButton = (<Button variant="ida" onClick={this.getDownload}>Generate Download</Button>);
            } else {
              downloadButton = (<Button variant="ida" href={this.state.downloadLink}>Download Data</Button>);
            }
          }

          data_table = (
            <DatatableWrapper
              body={this.state.data}
              headers={this.state.headers}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 10,
                  options: [5, 10, 15, 20],
                  button: "btn-ida"
                }
              }}
            >
              <Table
                responsive
              >
                <TableHeader />
                <TableBody />
              </Table>
              {paginationRow}
              {downloadButton}
            </DatatableWrapper>
          );
        }
      }
    }

    return (
      <Row className="mb-3">
        <Col md={{ span: 2 }} className="text-end"><img src={ida} height="80" width="80" alt="I.D.A." /></Col>
        <Col md={{ span: 7 }}>
          <Card text="black" className="rounded ida_bubble" style={{ "border-radius": "30px" }}>
            <Card.Body>
              <p>{this.state.response}</p>
              {data_table}
              {extras}
            </Card.Body>
          </Card>
        </Col>
      </Row >
    );
  }
}

export default InvokeIDAResponse;
