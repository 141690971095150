import React from 'react';

import {
  Row,
  Col,
} from 'react-bootstrap';

import ida from '../images/ida.png';

class InvokeIDAThinking extends React.Component {

  render() {
    return (
      <Row className="mb-3">
        <Col md={{ span: 2 }} className="text-end"><img src={ida} height="80" width="80" alt="I.D.A." /></Col>
        <Col md={{ span: 1 }}>
          <div class="dot-typing"></div>
        </Col>
      </Row>
    );
  }
}

export default InvokeIDAThinking;
