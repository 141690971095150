import React from 'react';

import {
  Card,
  Container,
  Row,
  Col
} from 'react-bootstrap';

// import formatDate from "../Utilities/FormatDate";

class InvokeAnalysisResultInfo extends React.Component {

  render() {

    let includedCohorts = [];
    let excludedCohorts = [];
    let cohortList = (
      <div>All available data</div>
    );
    let includedDataGroups = [];
    let usedDataSources = [];

    this.props.cohorts.forEach(
      (x, idx) => {
        if (x.selected) {
          if (x.state === "include") {
            includedCohorts.push(
              <li key={`include-filter-${idx}`}>{`Include ${x.name}`}</li>
            );
          } else {
            excludedCohorts.push(
              <li key={`exclude-filter-${idx}`}>{`Exclude ${x.name}`}</li>
            );
          }

        }
      }
    );

    if (
      (includedCohorts.length > 0) ||
      (excludedCohorts.length > 0)
    ) {
      cohortList = (
        <ul>
          {includedCohorts}
          {excludedCohorts}
        </ul>
      );
    }

    this.props.dataGroups.forEach(
      (x, idx) => {
        if (x.selected && x.state === "active") {
          includedDataGroups.push(
            <li key={`data-group-${idx}`}>{x.data_group}</li>
          );
        }
      }
    );

    let dataGroupList = (
      <ul>
        {includedDataGroups}
      </ul>
    );

    this.props.dataSources.forEach(
      (x, idx) => {
        usedDataSources.push(
          <li key={`data-source-${idx}`}>
            {x.originating_organization}. {x.title}. Retrieved from {x.url}.
          </li>
        )
      }
    );

    let dataSourceList = "Data source information is not available for this analysis.";

    if (usedDataSources.length > 0) {
      dataSourceList = (
        <ul>
          {usedDataSources}
        </ul>
      );
    }

    return(
      <Card>
        <Card.Body>
          <Card.Title>{this.props.product}</Card.Title>
          <Container>
            <Row className="py-2">
              <Col>{this.props.description}</Col>
            </Row>
            <Row className="py-2">
              <Col>
                <h5>Analysis Date: {this.props.analysisDate}</h5>
              </Col>
            </Row>
            <Row className="py-2">
              <Col>
                <h5>Cohorts</h5>
                {cohortList}
              </Col>
            </Row>
            <Row className="py-2">
              <Col>
                <h5>Included Data Groups</h5>
                {dataGroupList}
              </Col>
            </Row>
            <Row className="py-2">
              <Col>
                <h5>Data Sources Used</h5>
                {dataSourceList}
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

export default InvokeAnalysisResultInfo;