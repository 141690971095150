import React from 'react';

import { Auth0Context } from "@auth0/auth0-react";

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Form,
    ToastContainer,
    Toast,
    Spinner,
} from 'react-bootstrap';

import api_url from "../api_url";

class InvokeIDAManager extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            jrrToken: null,
            knowledge: "",
            alertVisible: false,
            knowledgeLoaded: false,
            alertMessage: "",
        };

        this.submitKnowledge = this.submitKnowledge.bind(this);
        this.setKnowledge = this.setKnowledge.bind(this);
    }

    componentDidMount() {
        const { getAccessTokenSilently } = this.context;
        getAccessTokenSilently({
            audience: api_url,
        }).then((t) => {
            this.setState({ jrrToken: "Bearer " + t });
            this.initializeData();
        }).catch((error) => {
            console.log(error);
        });
    }

    componentWillUnmount() {
    }

    initializeData() {
        this.getKnowledge();
    }

    getKnowledge() {
        const options = {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_knowledge'
            }
        };

        fetch(
            `${api_url}/ida/institution`,
            options
        ).then(
            response => response.text()
        ).then(
            (data) => {
                this.setState({
                    knowledge: data,
                    knowledgeLoaded: true,
                });
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    submitKnowledge() {
        const options = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'ida_knowledge'
            },
            body: this.state.knowledge,
        };

        this.setState({
            alertVisible: true,
            alertMessage: (
                <div>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    &nbsp;&nbsp;Updating IDA...
                </div>
            ),
        });

        fetch(
            `${api_url}/ida/institution`,
            options
        ).then(
            response => response.text()
        ).then(
            (data) => {
                this.setState({
                    alertVisible: true,
                    alertMessage: "Knowledge Successfully Updated!",
                });
                setTimeout(
                    () => {
                        this.setState(
                            {
                                alertVisible: false,
                                alertMessage: "",
                            }
                        );
                    },
                    3000
                );
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    setKnowledge(q) {
        this.setState({ knowledge: q.target.value });
    }

    render() {
        let knowledgeField = (<button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
        </button>);
        if (this.state.knowledgeLoaded) {
            knowledgeField = (
                <Form>
                    <Form.Group className="mb-3" controlId="user_knowledge">
                        <Form.Label><strong>Your Institutional Knowledge:</strong></Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={25}
                            value={this.state.knowledge}
                            onChange={this.setKnowledge}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={this.submitKnowledge}>
                        Save
                    </Button>
                    <ToastContainer>
                        <Toast show={this.state.alertVisible}>
                            <Toast.Header closeButton={false}>
                                Institutional Knowledge
                            </Toast.Header>
                            <Toast.Body>
                                {this.state.alertMessage}
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>
                </Form>);
        }

        return (
            <Container>
                <Row className="my-2">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Invoke Data Assistant (IDA) Institutional Knowledge</Card.Title>
                                <Card.Text>
                                    <p>
                                        IDA knows quite a bit out of the box, but
                                        may need help understanding how your organization
                                        uses data. Enter sentences/paragraphs below
                                        in plain english describing any institution-specific
                                        situations you'd like it to handle.
                                    </p>
                                    <p>
                                        For instance, you might enter "Students with 12 or more enrolled credit hours are considered Full-Time Students."
                                    </p>
                                </Card.Text>
                                {knowledgeField}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default InvokeIDAManager;