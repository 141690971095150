import React from 'react';

import {
  Modal,
  Button
} from 'react-bootstrap';

class InvokeConfirmationModal extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  render(){
    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={this.props.confirmHandler}
            variant="primary"
          >
            {this.props.confirmText}
          </Button>
          <Button
            onClick={this.props.cancelHandler}
            variant="secondary"
          >
            {this.props.cancelText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InvokeConfirmationModal;