import React from 'react';

import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faToggleOn,
  faToggleOff,
  faTimesCircle,
  faPlusCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

class InvokeAnalysisConfigurationItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleOff = this.handleToggleOff.bind(this);
    this.handleToggleOn = this.handleToggleOn.bind(this);
    this.handleAddRemove = this.handleAddRemove.bind(this);
  }

  handleToggleOff(e) {
    this.props.configurationChangeHandler(this.props, "toggleOff");
  }

  handleToggleOn(e) {
    this.props.configurationChangeHandler(this.props, "toggleOn");
  }

  handleAddRemove(e) {
    this.props.configurationChangeHandler(this.props, "addRemove");
  }

  render() {
    var toggle;

    if (this.props.isEnabled === true) {
      toggle = <span className="text-primary"><FontAwesomeIcon icon={faToggleOn} onClick={this.handleToggleOff}/></span>;
    } else {
      if (this.props.isEnabled === false) {
        toggle = <FontAwesomeIcon icon={faToggleOff} onClick={this.handleToggleOn}/>;
      } else {
        toggle = "";
      }
    }

    let opIcon = this.props.inUse ? faTimesCircle : faPlusCircle;
    let overlayKey = `overlay-${this.props.itemId}`;
    let tooltipKey = `tooltip-${this.props.itemId}`;
    let description = this.props.description;

    return(
      <Container>
        <Row>
          <Col sm={10}>
            <FontAwesomeIcon icon={opIcon} onClick={this.handleAddRemove} />
            &nbsp;&nbsp;{toggle}
            &nbsp;&nbsp;{this.props.itemName}
          </Col>
          <Col>
            <OverlayTrigger
              key={overlayKey}
              placement="right"
              overlay={
                <Tooltip id={tooltipKey}>
                  {description}
                </Tooltip>
              }
            >
              <span><FontAwesomeIcon icon={faInfoCircle} /></span>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default InvokeAnalysisConfigurationItem;