import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import App from './App';

class ProtectedApp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      iframeLoaded: false
    };

    this.flagIframeLoaded = this.flagIframeLoaded.bind(this);
  }

  flagIframeLoaded() {
    console.log("Handling Superset authentication IFRAME load event.");
    this.setState(
      { iframeLoaded: true }
    );
  }

  render() {
    var app;

    console.log("Superset authentication IFRAME loaded:", this.state.iframeLoaded);
    if (this.state.iframeLoaded) {
      app = <App />;
    } else {
      app = <iframe
        title="Authenticate with Superset"
        src="https://viz.invokeclairity.com/login/invokeAuth0"
        width="0"
        height="0"
        onLoad={this.flagIframeLoaded}
      />;
    }

    return <>{app}</>;
  }
}

export default withAuthenticationRequired(ProtectedApp);