import React from 'react';

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Accordion
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

class InvokeAnalysisResultSummary extends React.Component {

  chartsIncludeAdvancedMetrics(charts) {

    let advancedMetricTitles = [
      "Precision",
      "Recall",
      "ROC AUC",
      "Confusion Matrix"
    ];

    let allChartTitles = new Set(
      charts.map(
        (x) => x.chart_title
      )
    );

    let returnValue = true;

    advancedMetricTitles.forEach(
      (x) => returnValue = returnValue && allChartTitles.has(x)
    )

    return returnValue;
  }

  formatChartItems(analysisResult, chartNames) {

    let formattedCharts = [];

    chartNames.forEach(
      (el) => {
        const key = el.name.replaceAll(" ", "-");
        const rawValue = analysisResult[el.apiName];
        var value;

        if (el.formatAs === "percentage") {
          value = Math.round(Number.parseFloat(rawValue) * 100.0).toFixed() + " %";
        } else {
          if (el.formatAs.startsWith("fixed_")) {
            const places = Number.parseInt(el.formatAs.slice(6));
            value = Number.parseFloat(rawValue).toFixed(places);
          } else {
            // Assume that it's "verbatim"
            value = rawValue;
          }
        }

        if (
          (typeof el.warning !== "undefined") &&
          (el.warning.criterion(value))
        ) {
          formattedCharts.push(
            <Col key={key}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <div className="text-center">{el.name}</div>
                  </Card.Title>
                  <div className="text-center lead">{value}</div>
                </Card.Body>
                <Card.Footer>
                  <Container>
                    <Row>
                      <Col className="col-1"><h3><FontAwesomeIcon icon={faExclamationTriangle} /></h3></Col>
                      <Col className="col-1">&nbsp;</Col>
                      <Col className="col-9"><small>{el.warning.message}</small></Col>
                    </Row>
                  </Container>
                </Card.Footer>
              </Card>
            </Col>
          );
        } else {
          formattedCharts.push(
            <Col key={key}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    <div className="text-center">{el.name}</div>
                  </Card.Title>
                  <div className="text-center lead">{value}</div>
                </Card.Body>
              </Card>
            </Col>
          );
        }
      }
    );

    return formattedCharts;
  }

  formatConfusionMatrix(cm) {
    const fmtCm = [[null, null], [null, null]];

    cm.forEach(
      (row, i) => {
        row.forEach(
          (col, j) => {
            if (col === "N/A") {
              fmtCm[i][j] = col;
            } else {
              // Convert to percentage, but round to three decimal places.
              fmtCm[i][j] = (Math.round(Number.parseFloat(col) * 100000.0)/1000.0).toFixed(3) + " %";
            }
          }
        )
      }
    );

    return(
      <Col key="confusion-matrix">
        <Card>
          <Card.Body>
            <Card.Title>
              <div className="text-center">Confusion Matrix</div>
            </Card.Title>
            <Table borderless={true} className="text-center">
              <tbody>
                <tr>
                  <td>TP {fmtCm[0][0]}</td>
                  <td>FP {fmtCm[0][1]}</td>
                </tr>
                <tr>
                  <td>FN {fmtCm[1][0]}</td>
                  <td>TN {fmtCm[1][1]}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  render() {

    const analysisResult  = this.props.analysisResult;
    let advancedMetricsUi = <></>;

    const formattedSummaryCharts = this.formatChartItems(
      analysisResult,
      [
        {
          name: "Overall Accuracy",
          apiName: "accuracy",
          formatAs: "percentage"
        },
        {
          name: "Factors Considered",
          apiName: "factors_considered",
          formatAs: "verbatim"
        },
        {
          name: "Rows Analyzed",
          apiName: "rows_analyzed",
          formatAs: "verbatim",
          warning: {
            criterion: (x) => {
              // Value is a string formatted with commas.
              // Parse it out for arithmetic comparison.
              const numVal = parseInt(x.replace(/,/g, ""));
              if (numVal < 1500) {
                return true;
              } else {
                return false;
              }
            },
            message: "Low row counts may lead to unreliable results."
          }
        },
        {
          name: "Elapsed Time",
          apiName: "duration",
          formatAs: "verbatim"
        },
      ]
    );


    const formattedAdvancedCharts = this.formatChartItems(
      analysisResult,
      [
        {
          name: "Precision",
          apiName: "precision",
          formatAs: "fixed_5"
        },
        {
          name: "Recall",
          apiName: "recall",
          formatAs: "fixed_5"
        },
        {
          name: "ROC AUC",
          apiName: "auc",
          formatAs: "fixed_5"
        }
      ]
    );

    formattedAdvancedCharts.push(
      this.formatConfusionMatrix(
        analysisResult["confusion_matrix"]
      )
    );

    advancedMetricsUi = (
      <Row className="pt-2">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Advanced Metrics</Accordion.Header>
            <Accordion.Body>
              <Container className="py-2">
                <Row>{formattedAdvancedCharts}</Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );

    return(
      <Card>
        <Card.Body>
          <Card.Title>
            Analysis Summary
          </Card.Title>
          <Container className="py-2">
            <Row>
              {formattedSummaryCharts}
            </Row>
            {advancedMetricsUi}
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

export default InvokeAnalysisResultSummary;
