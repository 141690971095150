import React from 'react';

import {
  Container
} from 'react-bootstrap';

import InvokeIDAHumanQuestion from './InvokeIDAHumanQuestion';
import InvokeIDAResponse from './InvokeIDAResponse';
import InvokeIDAThinking from './InvokeIDAThinking';

class InvokeIDAInteractionList extends React.Component {

  render() {
    let interactionList = [];
    let i = 0;
    this.props.interactionHistory.forEach(
      (a) => {
        if (a.type === "human") {
          let interaction = (
            <InvokeIDAHumanQuestion key={i}
              question={a.text}
            />
          );
          interactionList.push(interaction);
        } else if (a.type === "ida_thinking") {
          let interaction = (
            <InvokeIDAThinking key={i}
              response={a.text}
            />
          );
          interactionList.push(interaction);
        } else {
          let interaction = (
            <InvokeIDAResponse key={i}
              jrrToken={this.props.jrrToken}
              response={a.text}
              history_id={a.history_id}
            />
          );
          interactionList.push(interaction);
        }
        i++;
      }
    );

    return (
      <Container>
        {interactionList}
      </Container>
    );
  }
}

export default InvokeIDAInteractionList;
