import InvokeEmbeddedSupersetDashboard from "./InvokeEmbeddedSupersetDashboard";

function InvokeEngagement(props) {
  return(
    <InvokeEmbeddedSupersetDashboard
      dashboardType="InvokeEngagement"
      dashboardDisplayName="Invoke Engagement"
    />
  );
}

export default InvokeEngagement;