import React from 'react';

import {
    Card,
    Spinner
} from 'react-bootstrap';

class InvokeAnalysisDescription extends React.Component {

  render() {
    let descriptionElement = (
      <Card>
        <Card.Body>
          <h4>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            &nbsp;&nbsp;Loading description...
          </h4>
        </Card.Body>
      </Card>
    );

    if (
      (this.props.productName !== null) &&
      (this.props.productDescription !== null)
    ) {
      descriptionElement = (
        <Card>
          <Card.Body>
            <Card.Title>{this.props.productName}</Card.Title>
            <Card.Text>{this.props.productDescription}</Card.Text>
          </Card.Body>
        </Card>
      );
    }

    return(descriptionElement);
  }
}

export default InvokeAnalysisDescription;