import InvokeEmbeddedSupersetDashboard from "./InvokeEmbeddedSupersetDashboard";

function InvokeEnrollmentTracker(props) {
  return(
    <InvokeEmbeddedSupersetDashboard
      dashboardType="InvokeEnrollmentTracker"
      dashboardDisplayName="Invoke Enrollment Tracker"
    />
  );
}

export default InvokeEnrollmentTracker;