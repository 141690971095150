import React from 'react';

import { Auth0Context } from "@auth0/auth0-react";

import {
    Button,
    Form,
    ToastContainer,
    Toast,
    Spinner,
} from 'react-bootstrap';

import api_url from "../api_url";

class InvokeIDAAdminPromptQA extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            jrrToken: null,
            prompt: "",
            alertVisible: false,
            alertMessage: "",
            promptLoaded: false,
        };

        this.submitPrompt = this.submitPrompt.bind(this);
        this.setPrompt = this.setPrompt.bind(this);
    }

    componentDidMount() {
        const { getAccessTokenSilently } = this.context;
        getAccessTokenSilently({
            audience: api_url,
        }).then((t) => {
            this.setState({ jrrToken: "Bearer " + t });
            this.initializeData();
        }).catch((error) => {
            console.log(error);
        });
    }

    componentWillUnmount() {
    }

    initializeData() {
        this.getPrompt();
    }

    getPrompt() {
        const options = {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'invoke_qans_prompt'
            }
        };

        fetch(
            `${api_url}/ida/prompt/qans`,
            options
        ).then(
            response => response.text()
        ).then(
            (data) => {
                this.setState({
                    prompt: data,
                    promptLoaded: true,
                });
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    submitPrompt() {
        const options = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.jrrToken,
                'Invoke-Action': 'invoke_qans_prompt'
            },
            body: this.state.prompt,
        };

        this.setState({
            alertVisible: true,
            alertMessage: (
                <div>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    &nbsp;&nbsp;Updating IDA...
                </div>
            ),
        });

        fetch(
            `${api_url}/ida/prompt/qans`,
            options
        ).then(
            response => response.text()
        ).then(
            (data) => {
                this.setState({
                    alertVisible: true,
                    alertMessage: "Prompt Successfully Updated!",
                });
                setTimeout(
                    () => {
                        this.setState(
                            {
                                alertVisible: false,
                                alertMessage: "",
                            }
                        );
                    },
                    3000
                );
            }
        ).catch(
            (error) => console.log(error)
        );
    }

    setPrompt(q) {
        this.setState({ prompt: q.target.value });
    }

    render() {
        let promptField = (<button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;&nbsp;Loading...
        </button>);
        let saveButon = "";
        if (this.state.promptLoaded) {
            promptField = (
                <Form.Control
                    as="textarea"
                    rows={25}
                    value={this.state.prompt}
                    onChange={this.setPrompt}
                />);
            saveButon = (
                <Button variant="primary" onClick={this.submitPrompt}>
                    Save
                </Button>);
        }

        return (
            <Form>
                <Form.Group className="mb-3" controlId="user_prompt">
                    <Form.Label><strong>Assistant Instructions:</strong></Form.Label>
                    <div>{promptField}</div>
                </Form.Group>
                {saveButon}
                <ToastContainer>
                    <Toast show={this.state.alertVisible}>
                        <Toast.Header closeButton={false}>
                            IDA
                        </Toast.Header>
                        <Toast.Body>
                            {this.state.alertMessage}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </Form>
        );
    }
}

export default InvokeIDAAdminPromptQA;