import React from 'react';

import { Link } from 'react-router-dom';

import {
  Button,
  Spinner,
  OverlayTrigger,
  Popover,
  Form
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faExclamationCircle,
  faChartBar,
  faCopy,
  faTable,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

class InvokeAnalysisListItem extends React.Component {

  constructor(props) {
    super(props);

    this.labelChangeMonitor = null;

    this.state = {
      label: props.label,
      lastSeenLabel: props.label,
      modifiedSinceLastSave: false
    }

    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleSettingsLoad = this.handleSettingsLoad.bind(this);
    this.handleAnalysisDeleteConfirm = this.handleAnalysisDeleteConfirm.bind(this);
  }

  generateFilterMarkup(filters) {

    let filterListItems = [];

    filters.include_filters.forEach(
      (fltr, index) => {
        filterListItems.push(
          <li key={`not-enough-include-${this.props.analysisName}-${index}`}>Include {fltr.name}</li>
        );
      }
    );

    filters.exclude_filters.forEach(
      (fltr, index) => {
        filterListItems.push(
          <li key={`not-enough-exclude-${this.props.analysisName}-${index}`}>Exclude {fltr.name}</li>
        );
      }
    );

    if (filterListItems.length === 0) {
      filterListItems.push(
        <li key={`not-enough-include-${this.props.analysisName}-0`}>All available records.</li>
      );
    }

    let filterMarkup = (
      <div className="container">
        <p>
          The cohort selected resulted in a dataset that was too small to
          analyze properly.
        </p>
        <p>
          The cohort selected was:
        </p>
        <ul>
          {filterListItems}
        </ul>
      </div>
    );

    return filterMarkup;
  }

  handleLabelChange(e) {
    if ((e.target.value === "") && (this.state.label === null)) {
      return;
    }

    this.setState({label: e.target.value})
  }

  handleSettingsLoad(e) {
    this.props.settingsLoadHandler(this.props.analysisName);
  }

  handleAnalysisDeleteConfirm(e) {
    this.props.analysisDeleteConfirmHandler(this.props.analysisName);
  }

  componentDidMount() {
    this.labelChangeMonitor = setInterval(
      () => {
        if (this.state.label !== this.state.lastSeenLabel) {
          this.setState({lastSeenLabel: this.state.label});
          this.props.labelSaveHandler(this.props.analysisName, this.state.label);
        }
      },
      1000
    );
  }

  componentWillUnmount() {
    if (this.labelChangeMonitor !== null) {
      clearInterval(this.labelChangeMonitor);
    }
  }

  render() {
    var statusButtons;

    switch (this.props.status) {
      case "RAW":
        statusButtons = (
          <span className="d-grid">
            <Button variant="primary">
              <Spinner size="sm" animation="border" />
              &nbsp;&nbsp;Step 1/5: Initializing AI
            </Button>
          </span>
        );
        break;
      case "STAGING":
        statusButtons = (
          <span className="d-grid">
            <Button variant="primary">
              <Spinner size="sm" animation="border" />
              &nbsp;&nbsp;Step 2/5: Staging Data
            </Button>
          </span>
        );
        break;
      case "EXTRACTING":
        statusButtons = (
          <span className="d-grid">
            <Button variant="primary">
              <Spinner size="sm" animation="border" />
              &nbsp;&nbsp;Step 3/5: Extracting Data
            </Button>
          </span>
        );
        break;
      case "ANALYZING":
        statusButtons = (
          <span className="d-grid">
            <Button variant="primary">
              <Spinner size="sm" animation="border" />
              &nbsp;&nbsp;Step 4/5: AI Processing
            </Button>
          </span>
        );
        break;
      case "POSTPROCESSING":
        statusButtons = (
          <span className="d-grid">
            <Button variant="primary">
              <Spinner size="sm" animation="border" />
              &nbsp;&nbsp;Step 5/5: Processing Results
            </Button>
          </span>
        );
        break;
      case "COOKED":
        statusButtons = (
          <span className="d-grid gap-2 d-md-flex justify-content-evenly">
            <Button
              variant="primary"
              onClick={this.handleSettingsLoad}
            >
              <FontAwesomeIcon icon={faCopy} />
              &nbsp;&nbsp;Load Settings
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="primary"
              as={Link}
              to={`/${this.props.product}/analyses/${this.props.analysisName}`}
            >
              <FontAwesomeIcon icon={faChartBar} />
              &nbsp;&nbsp;View Analysis
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="warning"
              onClick={this.handleAnalysisDeleteConfirm}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </span>
        );
        break;
      case "NOT_ENOUGH_DATA":
        statusButtons = (
          <span className="d-grid gap-2 d-md-flex justify-content-evenly">
            <Button
              variant="primary"
              onClick={this.handleSettingsLoad}
            >
              <FontAwesomeIcon icon={faCopy} />
              &nbsp;&nbsp;Load Settings
            </Button>
            &nbsp;&nbsp;
            <Button variant="warning">
              <OverlayTrigger
                id="not-enough-data-overlay-trigger"
                placement="top"
                overlay={
                  <Popover id="not-enough-data-popover">
                    {this.generateFilterMarkup()}
                  </Popover>
                }
              >
                <span><FontAwesomeIcon icon={faTable} /></span>
              </OverlayTrigger>
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;&nbsp;Empty Dataset
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="warning"
              onClick={this.handleAnalysisDeleteConfirm}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </span>
        );
        break;
      case "FAILED":
        statusButtons = (
          <span className="d-grid gap-2 d-md-flex justify-content-evenly">
            <Button
              variant="primary"
              onClick={this.handleSettingsLoad}
            >
              <FontAwesomeIcon icon={faCopy} />
              &nbsp;&nbsp;Load Settings
            </Button>
            &nbsp;&nbsp;
            <Button variant="warning">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;&nbsp;Analysis Failed
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="warning"
              onClick={this.handleAnalysisDeleteConfirm}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </span>
        );
        break;
      case "HOMOGENEOUS_TARGET":
        statusButtons = (
          <span className="d-grid gap-2 d-md-flex justify-content-evenly">
            <Button
              variant="primary"
              onClick={this.handleSettingsLoad}
            >
              <FontAwesomeIcon icon={faCopy} />
              &nbsp;&nbsp;Load Settings
            </Button>
            &nbsp;&nbsp;
            <Button variant="warning">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;&nbsp;All Outcomes Identical
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="warning"
              onClick={this.handleAnalysisDeleteConfirm}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </span>
        );
        break;
      default:
        console.log(`Analysis status of ${this.props.status} was not an expected value.`);
    }

    let inputValue = "";

    if (this.state.label !== null) {
        inputValue = this.state.label;
    }

    let displayAccuracy  = this.props.accuracy;
    if (typeof displayAccuracy === "number") {
      displayAccuracy = Math.round(displayAccuracy * 100.0);
      displayAccuracy = `${displayAccuracy} %`
    }

    return(
      <tr>
        <td>
          <Form as="span">
            <Form.Control
              id={`analysis-label-${this.props.sequenceNumber}`}
              type="text"
              size="sm"
              value={inputValue}
              onChange={this.handleLabelChange}
            />
          </Form>
        </td>
        <td>{displayAccuracy}</td>
        <td>{this.props.createdOn}</td>
        <td>{statusButtons}</td>
      </tr>
    );
  }
}

export default InvokeAnalysisListItem;