function coerceConfigStateToNullableBoolean(itemType, state) {
  switch (itemType) {
    case "dataGroup":
      switch (state) {
        case "active":
          return true;
        case "inactive":
          return false;
        case null:
          return null;
        default:
          throw new Error(`The value "${state}" is invalid for a data group item.`)
      }
    case "cohort":
      switch (state) {
          case "include":
            return true;
          case "exclude":
            return false;
          case null:
            return null;
          default:
            throw new Error(`The value "${state}" is invalid for a cohort item.`);
      }
    default:
      throw new Error(`The value "${itemType}" is an invalid item type.`);
  }
}

export default coerceConfigStateToNullableBoolean;