import react from "react";

import {
  Container,
  Row,
  Col,
  Spinner
} from "react-bootstrap";

import { Auth0Context } from "@auth0/auth0-react";

import api_url from "../api_url";

class InvokeEmbeddedSupersetDashboard extends react.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);

    this.state = {
      dashboardId: null,
      projectId: null
    };
  }

  componentDidMount() {
    const { getAccessTokenSilently } = this.context;
    getAccessTokenSilently({
      audience: api_url,
    }).then((t) => {
      this.setState({ jrrToken: "Bearer " + t });
      this.initializeData();
    }).catch((e) => {
      console.log(e);
    });
  }

  initializeData() {
    const options = {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.state.jrrToken,
        'Invoke-Action': 'dashboard'
      }
    };

    fetch(
      `${api_url}/dashboard/${this.props.dashboardType}`,
      options
    ).then(
      response => response.json()
    ).then(
      (data) => {
        this.setState(
          {
            dashboardId: data.dashboard_id,
            projectId: data.project_id
          }
        );
      }
    ).catch(
      (error) => console.log(error)
    );
  }

  render() {
    let dashboardId = this.state.dashboardId;

    if (dashboardId === null) {
      return (
        <Container className="my-5">
          <Row>
            <Col className="text-center">
              <h1>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                &nbsp;&nbsp;Loading {this.props.dashboardDisplayName} dashboard...
              </h1>
            </Col>
          </Row>
        </Container>
      );
    } else {

      const embedUrl = `https://viz.invokeclairity.com/superset/dashboard/${dashboardId}/?standalone=1`;

      return (
        <iframe
          src={embedUrl}
          title="{this.props.dashboardDisplayName}"
          height="1500"
          width="100%"
        />
      );
    }
  }
}

export default InvokeEmbeddedSupersetDashboard;