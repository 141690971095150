import React from 'react';

import {
  Card,
  Table,
  Spinner
} from 'react-bootstrap';

import InvokeAnalysisListItem from "./InvokeAnalysisListItem";
import formatTimestamp from '../Utilities/FormatTimestamp';

class InvokeAnalysisList extends React.Component {

  render() {
    var cardTable;
    let analysisList = [];

    if (this.props.analyses === null) {
      cardTable = (
        <h5>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          &nbsp;&nbsp;Loading analyses...
        </h5>
      );
    } else {
      if (this.props.analyses.length === 0) {
        cardTable = (
          <h6 className="d-flex justify-content-center">No analyses have been run.</h6>
        );
      } else {
        const sortedAnalyses = JSON.parse(JSON.stringify(this.props.analyses));
        // Show newest analyses first.
        sortedAnalyses.sort(
          (x, y) => {
            if (x.analysis_name > y.analysis_name) {
              return -1
            }
            if (x.analysis_name < y.analysis_name) {
              return 1;
            }
            return 0;
          }
        );
        sortedAnalyses.forEach(
          (a) => {
            let listItem = (
              <InvokeAnalysisListItem
                product={this.props.product}
                key={`analysis-${a.analysis_name}`}
                analysisName={a.created_date}
                label={a.label}
                accuracy={a.accuracy}
                createdOn={formatTimestamp(a.created_date, "T")}
                status={a.status}
                labelSaveHandler={this.props.labelSaveHandler}
                settingsLoadHandler={this.props.settingsLoadHandler}
                analysisDeleteConfirmHandler={this.props.analysisDeleteConfirmHandler}
              />
            );
            analysisList.push(listItem);
          }
        );

        cardTable = (
          <Table>
            <thead>
              <tr>
                <td>
                  Label
                </td>
                <td>
                  Accuracy
                </td>
                <td>
                  Created On
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
            </thead>
            <tbody>
              {analysisList}
            </tbody>
          </Table>
        );
      }
    }

    return(
      <Card>
        <Card.Body>
          <Card.Title>Analyses</Card.Title>
          {cardTable}
        </Card.Body>
      </Card>
    );
  }
}

export default InvokeAnalysisList;
