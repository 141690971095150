import React from 'react';

import {
  Card
} from 'react-bootstrap';

class InvokeAnalysisResultFactorProfile extends React.Component {
  render() {

    const analysisName = this.props.analysisName;
    const dashboardId = this.props.dashboardId;
    const productName = this.props.productName;
    const username = this.props.username;

    const embedUrl = `https://viz.invokeclairity.com/superset/dashboard/${dashboardId}/?standalone=1&native_filters=(NATIVE_FILTER-91jBCgGRW:(__cache:(label:${productName},validateMessage:!f,validateStatus:!f,value:!(${productName})),extraFormData:(filters:!((col:product,op:IN,val:!(${productName})))),filterState:(label:${productName},validateMessage:!f,validateStatus:!f,value:!(${productName})),id:NATIVE_FILTER-91jBCgGRW,ownState:()),NATIVE_FILTER-BYAs-Wf7Q:(__cache:(label:'${username}',validateMessage:!f,validateStatus:!f,value:!('${username}')),extraFormData:(filters:!((col:username,op:IN,val:!('${username}')))),filterState:(label:'${username}',validateMessage:!f,validateStatus:!f,value:!('${username}')),id:NATIVE_FILTER-BYAs-Wf7Q,ownState:()),NATIVE_FILTER-_LdShz1yY:(extraFormData:(),filterState:(),id:NATIVE_FILTER-_LdShz1yY,ownState:()),NATIVE_FILTER-_gkRKxrCC:(__cache:(label:'${analysisName}',validateMessage:!f,validateStatus:!f,value:!('${analysisName}')),extraFormData:(filters:!((col:analysis_name,op:IN,val:!('${analysisName}')))),filterState:(label:'${analysisName}',validateMessage:!f,validateStatus:!f,value:!('${analysisName}')),id:NATIVE_FILTER-_gkRKxrCC,ownState:()),NATIVE_FILTER-nuWK4RfTU:(extraFormData:(),filterState:(),id:NATIVE_FILTER-nuWK4RfTU,ownState:()))`;

    return (
      <Card>
        <iframe
          src={embedUrl}
          title="Anaysis Result"
          width="100%"
          height="1050px"
        />
      </Card>
    );
  }
}

export default InvokeAnalysisResultFactorProfile;