function formatTimestamp(fullTimestamp, timeDateSeparator) {
    let [calendarDate, timestamp] = fullTimestamp.split(timeDateSeparator);
    let [year, month, day] = calendarDate.split("-");
    let [hour, minute, second] = timestamp.split(".")[0].split(":");
    let hourAsInt = parseInt(hour);
    var meridium = "AM";

    if (hourAsInt === 0) {
      hourAsInt += 12;
      meridium = "AM";
    } else {
      if (hourAsInt >= 12) {
        if (hourAsInt > 12) {
            hourAsInt -= 12;
        }  
        meridium = "PM";
      }
    }
    hour = Number(hourAsInt).toString();
    return `${month}/${day}/${year}, ${hour}:${minute}:${second} ${meridium}`
  }

  export default formatTimestamp;