import React from 'react';

import {
  Row,
  Col,
  Card,
} from 'react-bootstrap';

import human from '../images/human.png';

class InvokeIDAHumanQuestion extends React.Component {

  render() {
    return (
      <Row className="mb-3">
        <Col md={{ span: 7, offset: 3 }} className="text-end">
          <Card className="rounded human_bubble">
            <Card.Body>{this.props.question}</Card.Body>
          </Card>
        </Col>
        <Col md={{ span: 2 }}><img src={human} height="80" width="80" alt="HUMAN PERSON" /></Col>
      </Row>
    );
  }
}

export default InvokeIDAHumanQuestion;
