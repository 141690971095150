import {
    Container,
    Row,
    Col
} from "react-bootstrap";

function InvokeWelcome(props) {
    return(
        <Container className="text-center my-5">
            <Row>
                <Col>
                    <h1>Welcome to Invoke Clairity!</h1>
                    <p className="lead">To begin, click one of the items on the <strong>Products</strong> navigation menu above.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default InvokeWelcome;